

























































import {
  defineComponent, onMounted, computed,
} from '@nuxtjs/composition-api';
import {
  useUiHelpers,
  useImage,
} from '~/composables';
import { addBasePath } from '~/helpers/addBasePath';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import ImageWrapper from '~/components/image/ImageWrapper.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'CategoryToggleMenu',
  components: {
    ImageWrapper,
    SkeletonLoader,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    fetchedCategories: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { search, result } = useCategorySearch();
    const { getCatLink } = useUiHelpers();
    const { getMagentoImage } = useImage();
    onMounted(async () => {
      if (props.fetchedCategories[props.category.uid]) return;

      await search({
        filters: {
          parent_category_uid: {
            eq: props.category.uid, // 562 is the id of the brand category
          },
        },
      });

      emit('categoriesFetched', props.category.uid, result.value);
    });

    const searchedCategories = computed(() => {
      if (result && result.value?.length > 0) return result.value;
      if (props.fetchedCategories[props.category.uid]) return props.fetchedCategories[props.category.uid];
      return [];
    });

    return {
      getCategoryImage,
      getCatLink,
      addBasePath,
      searchedCategories,
      getMagentoImage,
    };
  },
});
